import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { UtilService } from "src/app/shared/service/util.service";
import { ContactService } from "src/app/shared/service/contact.service";
import { Contact } from "src/app/shared/model/contact";
import { MatSnackBar } from "@angular/material/snack-bar";
import { forkJoin } from "rxjs";

@Component({
    selector: "app-contact-dialog",
    templateUrl: "./contact-dialog.component.html",
    styleUrls: ["./contact-dialog.component.css"],
})
export class ContactDialogComponent implements OnInit {
    contactForm: FormGroup;
    isEditing: boolean;
    typeOptions: any[] = [];
    networkOptions: any[] = [];
    isLoading: boolean;

    constructor(
        public dialogRef: MatDialogRef<ContactDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { contact: Contact; isEditing: boolean },
        private fb: FormBuilder,
        private translateService: TranslateService,
        private utilService: UtilService,
        private contactService: ContactService,
        private snackBar: MatSnackBar
    ) {
        this.contactForm = this.fb.group({
            id: [null],
            name: ["", Validators.required],
            email: ["", [Validators.required, Validators.email]],
            zone: ["", Validators.required],
            contactTypes: [[], Validators.required],
            networks: [[], Validators.required],
        });
        this.isEditing = data.isEditing;
    }

    ngOnInit(): void {
        forkJoin({
            types: this.contactService.getContactTypes(),
            networks: this.contactService.getNetworks(),
        }).subscribe(
            ({ types, networks }) => {
                this.typeOptions = types;
                this.networkOptions = networks;

                if (this.isEditing && this.data.contact) {
                    const contactTypesIds = this.mapToIds(
                        this.data.contact.contactTypes,
                        this.typeOptions,
                        "type"
                    );
                    const networksIds = this.mapToIds(
                        this.data.contact.networks,
                        this.networkOptions,
                        "nameEn"
                    );

                    this.contactForm.setValue({
                        id: this.data.contact.id,
                        name: this.data.contact.name,
                        email: this.data.contact.email,
                        zone: this.data.contact.zone || "",
                        contactTypes: contactTypesIds,
                        networks: networksIds,
                    });

                    console.log(
                        "Formulario cargado en edición:",
                        this.contactForm.value
                    );
                } else {
                    console.log("Opciones cargadas:", { types, networks });
                }
            },
            (error) => {
                console.error("Error al cargar tipos o redes:", error);
            }
        );
    }

    onSave(): void {
        this.isLoading = true;
        if (this.contactForm.invalid) {
            this.isLoading = false;
            this.snackBar.open("Please fill in all required fields.", "Close", {
                duration: 3000,
            });
            return;
        }

        const contactData = this.contactForm.value;
        console.log("Datos enviados al backend:", contactData);

        if (this.isEditing) {
            this.contactService.updateContact(contactData).subscribe({
                next: (response) => {
                    this.isLoading = false;

                    this.snackBar.open(
                        "Contact updated successfully!",
                        "Close",
                        {
                            duration: 3000,
                            panelClass: ["SuccessfulSnackbar"],
                        }
                    );
                    this.dialogRef.close(false);
                },
                error: (err) => {
                    console.error("Error updating contact:", err);
                    this.snackBar.open(
                        "Failed to update contact. Try again later.",
                        "Close",
                        {
                            duration: 3000,
                        }
                    );
                },
            });
        } else {
            this.contactService.addContact(contactData).subscribe({
                next: () => {
                    console.log("creado");
                    this.isLoading = false;

                    this.snackBar.open(
                        "Contact created successfully!",
                        "Close",
                        {
                            duration: 3000,
                            panelClass: ["SuccessfulSnackbar"],
                        }
                    );
                    this.dialogRef.close(false);
                },
                error: () => {
                    this.isLoading = false;

                    this.snackBar.open(
                        "Failed to create contact. Try again later.",
                        "Close",
                        {
                            duration: 3000,
                            panelClass: ["error-snackbar"],
                        }
                    );
                },
            });
        }
    }

    onCancel(): void {
        this.dialogRef.close(false);
    }

    private mapToIds(names: string[], options: any[], key: string): number[] {
        return names
            .map((name) => {
                const option = options.find((opt) => opt[key] === name);
                return option ? option.id : null;
            })
            .filter((id) => id !== null);
    }
}
