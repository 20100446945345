import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Contact } from "../model/contact";
import { catchError } from "rxjs/operators";
import { GeneralService } from "./general.service";
import { ContactType } from "../model/contactType";
import { ContactContactType } from "../model/contact-contact-type";

@Injectable({
    providedIn: "root",
})
export class ContactService {
    private apiUrl = environment.apiSecurityUrlContact;

    constructor(
        private http: HttpClient,
        private gralService: GeneralService
    ) {}

    getAllActiveContacts(): Observable<Contact[]> {
        return this.http.get<Contact[]>(
            `${this.apiUrl}/getAllActiveWithRelations`
        );
    }

    addContact(contact: Contact): Observable<Contact> {
        console.log("contacto en service:", contact);

        const url = `${this.apiUrl}/add`;

        const contactData = {
            id: contact.id,
            version: contact.version,
            email: contact.email,
            name: contact.name,
            zone: contact.zone,
            is_deleted: contact.is_deleted,
            selected: contact.selected,
            contactTypes: contact.contactTypes,
            networks: contact.networks
        };

        return this.http.post<any>(url, contactData).pipe(
            catchError(this.gralService.handleError)
        );
    }

    updateContact(contact: Contact): Observable<HttpResponse<void>> {
        return this.http.put<void>(
            `${this.apiUrl}/edit/${contact.id}`, contact ,{ observe: 'response' }
        );
    }

    deleteContactList(ids: number[]): Observable<void> {
        return this.http
            .put<void>(`${this.apiUrl}/deleteList`, ids)
            .pipe(catchError(this.gralService.handleError));
    }

    getContactContactTypes(): Observable<ContactContactType[]> {
        return this.http.get<ContactContactType[]>(
            `${this.apiUrl}/getAllContactTypeRelations`
        );
    }

    getContactTypes(): Observable<ContactType[]> {
        console.log("ContactTypes (Service) 2:", ContactType);
        return this.http.get<ContactType[]>(
            `${this.apiUrl}/getAllContactTypes`
        );
    }

    getNetworks(): Observable<any[]> {
        return this.http.get<any[]>(environment.apiNetworkEpgGetActiveNetworks);
    }
}
