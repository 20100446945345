import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NetworkEpg } from 'src/app/shared/model/network-epg-model/network-epg';
import { NetworkEpgFilesUpload } from 'src/app/shared/model/network-epg-model/network-epg-files-upload';
import { NewNetworkEpg } from 'src/app/shared/model/new-network-epg';
import { NetworkEpgService } from 'src/app/shared/service/network-epg.service';
import { environment } from 'src/environments/environment';
import { uniqueCodeValidator } from '../../../../shared/utils/unique-code.validator';

@Component({
  selector: 'app-create-network',
  templateUrl: './create-network.component.html',
  styleUrls: ['./create-network.component.scss']
})
export class CreateNetworkComponent implements OnInit {

  private unsubscription$: Subject<void>;
  @ViewChild('formDirective') private formDirective: NgForm;


  newNetwork: NewNetworkEpg = {
    id: null,
    nameEn: "",
    nameEs: "",
    namePt: "",
    code: "",
    informationEn: "",
    informationEs: "",
    informationPt: "",
    urlsEn: "",
    urlsEs: "",
    urlsPt: "",
    urlTwitterEn: "",
    urlTwitterEs: "",
    urlTwitterPt: "",
    urlFacebookEn: "",
    urlFacebookEs: "",
    urlFacebookPt: "",
    feedText: "",
    feedHorarios: "",
    visible: false,
    active: false,
    activepcm: false,
    logo: "",
    factSheet: "",
    pressKit: "",
    feedImage: ""

  }
  networkForm: FormGroup;
  networkEpgLogoUpload: NetworkEpgFilesUpload;
  networkEpgReflectedLogoUpload: NetworkEpgFilesUpload;
  logoUrl: string;
  listNetworks: NetworkEpg[] = [];
  imageExtensions: string[] = [];
  defaultLogoUrl: string = environment.bucketUrlImages + "WBD_logo_news.png";
  maxWidthLogo: number = 240;
  maxHeightLogo: number = 120;
  maxWidthLogoRef: number = 97;
  maxHeightLogoRef: number = 96;
  allowedExtension: string = ".png";
  updateInProgress: boolean = false;


  // variables para mensajes del snackbar.
  publicationFilesInProcessMessage: string;
  successfulFilesPublicationMessage: string;

  // variables para mensajes del snackbar.
  errorMessage: string;
  errorDBMessage: string;


  //si el componente esta en modo edicion
  isEdit: boolean;
  imageMessage: string = "";
  networkImage: string = "";
  networkReflectedImage = ""
  imageReflectedMessage: string = "";

  // variables del html.
  savingInProgress: boolean = false;

  //validators
  existingNetworkCodes: string[] = [];
  isCodesLoaded: boolean = false;

  constructor(private fb: FormBuilder,
    private networkEpgService: NetworkEpgService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,


  ) {

    this.unsubscription$ = new Subject<void>();
    this.networkEpgLogoUpload = new NetworkEpgFilesUpload();
    this.networkEpgReflectedLogoUpload = new NetworkEpgFilesUpload();

    this.logoUrl = environment.bucketUrlImagesNewsLogo;

    this.networkForm = this.fb.group({
      name_en: ['', Validators.required],
      name_es: ['', Validators.required],
      name_pt: ['', Validators.required],
      code: ['', Validators.required],
      information_en: ['', Validators.required],
      information_es: ['', Validators.required],
      information_pt: ['', Validators.required],
      url_en: ['', [Validators.required, Validators.pattern('^(https?://|www\\.)[a-zA-Z0-9._%+-/]+')]],
      url_es: ['', [Validators.required, Validators.pattern('^(https?://|www\\.)[a-zA-Z0-9._%+-/]+')]],
      url_pt: ['', [Validators.required, Validators.pattern('^(https?://|www\\.)[a-zA-Z0-9._%+-/]+')]],
      url_twitter_en: ['', [Validators.pattern('^(https?://(twitter.com|x.com)/.+|www\\.(twitter.com|x.com)/.+)')]],
      url_twitter_es: ['', [Validators.pattern('^(https?://(twitter.com|x.com)/.+|www\\.(twitter.com|x.com)/.+)')]],
      url_twitter_pt: ['', [Validators.pattern('^(https?://(twitter.com|x.com)/.+|www\\.(twitter.com|x.com)/.+)')]],
      url_facebook_en: ['', [Validators.pattern('^(https?://(facebook.com|www\\.facebook.com)/.+)')]],
      url_facebook_es: ['', [Validators.pattern('^(https?://(facebook.com|www\\.facebook.com)/.+)')]],
      url_facebook_pt: ['', [Validators.pattern('^(https?://(facebook.com|www\\.facebook.com)/.+)')]],
      feeds: [''],
      horarios: [''],
      visible: [true],
      active: [false]
    });
  }

  ngOnInit(): void {

    this.networkImage = this.defaultLogoUrl;
    this.networkReflectedImage = this.defaultLogoUrl;

    this.translateService.stream([
      'epg.networks_upload.messages.errorUpload',
      'epg.networks_upload.messages.errorUpload',
      'epg.networks_upload.messages.successUpload'
    ])
      .pipe(takeUntil(this.unsubscription$))
      .subscribe(translations => this.mapperToMessages(translations));


    this.loadExistingCodes();


  }



  save() {
    if (this.networkForm.valid) {
      this.savingInProgress = true;

      this.uploadToS3(null);
    }
  }

  uploadToS3(id) {
    if (this.networkEpgLogoUpload.networkFile != null) {
      this.networkEpgLogoUpload.fileName = (this.networkForm.value.code + this.allowedExtension).toLowerCase();
    }

    if (this.networkEpgReflectedLogoUpload.networkFile != null) {
      this.networkEpgReflectedLogoUpload.fileName = (this.networkForm.value.code + this.allowedExtension).toLowerCase();
    }

    let epgUpload = {
      logo: this.networkEpgLogoUpload,
      reflectedLogo: this.networkEpgReflectedLogoUpload
    };

    this.networkEpgService.uploadFilesToS3(epgUpload).subscribe({
      next: () => {
        this.registerInDB(id);
      },
      error: () => {
        this.showSnackbar(this.errorMessage, "Ok");
        this.savingInProgress = false;
      }
    });
  }

  registerInDB(id) {
    const formValues = this.networkForm.value;
    const selectedNetwork = this.listNetworks.find(network => network.code === formValues.network.code);

    this.newNetwork = {
      id: id,
      active: formValues.active,
      code: formValues.code.toUpperCase(),
      factSheet: null,
      informationEn: formValues.information_en,
      informationEs: formValues.information_es,
      informationPt: formValues.information_pt,
      logo: this.networkEpgLogoUpload.fileName,
      nameEn: formValues.name_en,
      nameEs: formValues.name_es,
      namePt: formValues.name_pt,
      urlFacebookEn: formValues.url_facebook_en,
      urlFacebookEs: formValues.url_facebook_es,
      urlFacebookPt: formValues.urlFacebookEs,
      urlTwitterEn: formValues.url_twitter_en,
      urlTwitterEs: formValues.url_twitter_es,
      urlTwitterPt: formValues.url_twitter_pt,
      urlsEn: formValues.url_en,
      urlsEs: formValues.url_es,
      urlsPt: formValues.url_pt,
      feedImage: null,
      feedText: formValues.feeds,
      feedHorarios: formValues.feedHorarios,
      pressKit: null,
      visible: true, //hace visibles las imágenes 
      activepcm: false
    };

    this.networkEpgService.saveNetworkEpg(this.newNetwork).subscribe({
      next: (res) => {
        this.showSnackbar(this.successfulFilesPublicationMessage, "Ok");
        this.savingInProgress = false;

        this.imageMessage = "";
        this.imageReflectedMessage = "";
        this.networkImage = this.defaultLogoUrl;
        this.networkReflectedImage = this.defaultLogoUrl;
        this.formDirective.resetForm();
        this.networkForm.reset();
        this.loadExistingCodes();


      },
      error: () => {
        this.showErrorSnackbar(this.errorDBMessage, "Ok");
      }
    });
  }

  private resetFormState() {
    this.savingInProgress = false;
    this.imageMessage = "";
    this.imageReflectedMessage = "";
    this.networkImage = this.defaultLogoUrl;
    this.networkReflectedImage = this.defaultLogoUrl;
    this.formDirective.resetForm();
    this.networkForm.reset();
  }

  showSnackbar(message: string, action: string) {
    let config = new MatSnackBarConfig();
    config.duration = 3000;
    config.verticalPosition = "top";
    config.panelClass = ['SuccessfulSnackbar'];
    this.snackBar.open(message, action, config);
  }

  showErrorSnackbar(message: string, action: string) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = "top";
    config.panelClass = ['error-snackbar'];
    this.snackBar.open(message, action, config);
  }

  onImageSelected(event: any) {
    const selectedFile = event.target.files[0];

    if (!selectedFile) return;
    const fileName = selectedFile.name;
    const fileExtension = fileName.split('.').pop().toLowerCase();
    const reader = new FileReader();

    this.networkEpgLogoUpload.fileName = selectedFile;

    this.networkEpgLogoUpload.networkFile = selectedFile;

    if (!this.imageExtensions.includes(fileExtension)) {
      this.imageMessage = `El archivo ${fileName} no tiene una extensión válida.`;
    }
    else if (selectedFile.size === 0) {
      this.imageMessage = `El archivo ${fileName} se encuentra vacío.`;
    }


    reader.readAsDataURL(selectedFile);
    reader.onload = (e: any) => {

      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        const height = img.naturalHeight;
        const width = img.naturalWidth;
        if (height != 120 && width != 240) {
          this.imageMessage = `El archivo ${fileName} no tiene el tamaño adecuado \ (${this.maxWidthLogo}px x ${this.maxWidthLogo}px).`;
        } else {
          this.imageMessage = fileName;
          this.networkImage = e.target.result;
        }
      };
    };
  }

  onImageReflectedSelected(event: any) {

    const selectedFile = event.target.files[0];

    if (!selectedFile) return;
    const fileName = selectedFile.name;
    const fileExtension = fileName.split('.').pop().toLowerCase();
    const reader = new FileReader();

    this.networkEpgReflectedLogoUpload.fileName = selectedFile;

    const networkCode = this.networkForm.value.code

    this.networkEpgReflectedLogoUpload.fileName = (networkCode + "." + fileExtension).toLowerCase();
    this.networkEpgReflectedLogoUpload.networkFile = selectedFile;

    if (!this.imageExtensions.includes(fileExtension)) {
      this.imageReflectedMessage = `El archivo ${fileName} no tiene una extensión válida.`;
    }
    else if (selectedFile.size === 0) {
      this.imageReflectedMessage = `El archivo ${fileName} se encuentra vacío.`;
    }

    reader.readAsDataURL(selectedFile);
    reader.onload = (e: any) => {

      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        const height = img.naturalHeight;
        const width = img.naturalWidth;
        if (height != 96 && width != 97) {
          this.imageReflectedMessage = `El archivo ${fileName} no tiene el tamaño adecuado \ (${this.maxWidthLogoRef}px x ${this.maxWidthLogoRef}px).`;
        } else {
          this.imageReflectedMessage = fileName;
          this.networkReflectedImage = e.target.result;
        }
      };
    };
  }


  private mapperToMessages(translations: any): void {
    this.errorMessage = translations['epg.networks_upload.messages.errorUpload'];
    this.errorDBMessage = translations['epg.networks_upload.messages.errorUpload'];
    this.successfulFilesPublicationMessage = translations['epg.networks_upload.messages.successUpload'];
  }

  loadExistingCodes() {
    this.networkEpgService.getAllNetwork().pipe(
      takeUntil(this.unsubscription$)
    ).subscribe({ 
      next : (result) => {
      this.existingNetworkCodes = result.map(network => network.code);

      this.networkForm.get('code').setValidators([
        Validators.required,
        uniqueCodeValidator(this.existingNetworkCodes)
      ]);

      this.networkForm.get('code').updateValueAndValidity();

      this.isCodesLoaded = true;
    } 
   });
  }

}

