import { Component, OnDestroy, OnInit } from "@angular/core";
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Role } from "src/app/shared/model/role";
import { AlertService } from "src/app/shared/service/alert.service";
import { AuthenticationService } from "src/app/shared/service/authentication.service";
import { AuthorizationService } from "src/app/shared/service/authorization.service";
import { CategoryService } from "src/app/shared/service/category.service";
import { UrlService } from "src/app/shared/service/url.service";
import { TermsComponent } from "../terms/terms.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { NewUser } from "src/app/shared/model/new-user";
import { NewAddress } from "src/app/shared/model/new-user";
import { NewCompany } from "src/app/shared/model/new-user";
import { Category } from "src/app/shared/model/category";
import { CountryService } from "src/app/shared/service/country.service";
import { Country } from "src/app/shared/model/country";
import { CountrySelect } from "src/app/shared/model/countrySelect";
import { LanguageSelect } from "src/app/shared/model/languageSelect";
import { LanguageService } from "src/app/shared/service/language.service";
import { CategorySelect } from "src/app/shared/model/categorySelect";

@Component({
    selector: "app-register",
    templateUrl: "./register.component.html",
    styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit, OnDestroy {
    newUser: NewUser = {
        firstName: "",
        lastName: "",
        username: "",
        password: "",
        language: "",
        type: "",
        address: {
            city: "",
            country: "",
            street: "",
            zipCode: "",
            version: 0,
        },
        company: {
            name: "",
            version: 0,
        },
        enabled: false,
        version: 0,
        accountExpired: false,
        accountLocked: false,
        passwordExpired: false,
        dateCreated: "",
        userClass: "",
        state: 0,
    };
    registerForm: FormGroup;
    loading = false;
    submitted = false;
    passwordStrength = 0;
    message: string = "";
    categories: CategorySelect[];
    countries: CountrySelect[];
    languages: LanguageSelect[];


    private unsubscription$: Subject<void>;
    redirectUrl$: string | null;

    successMessageKey: boolean = false;
    errorMessageKey: boolean = false;
    userAlreadyExistsMessageKey: boolean = false;
    invalidEmailMessageKey: boolean = false;
    passwordVisible: boolean = false;
    confirmPasswordVisible: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService,
        private authorizationService: AuthorizationService,
        private alertService: AlertService,
        private urlService: UrlService,
        private categoryService: CategoryService,
        private countryService: CountryService,
        private languageService: LanguageService,
        private dialog: MatDialog,
        private translateService: TranslateService,
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer
    ) {
        iconRegistry.addSvgIcon(
            "person",
            sanitizer.bypassSecurityTrustResourceUrl(
                "../../../assets/icons/filled-person-24px.svg"
            )
        );
        iconRegistry.addSvgIcon(
            "lock",
            sanitizer.bypassSecurityTrustResourceUrl(
                "../../../assets/icons/filled-lock-24px.svg"
            )
        );
        iconRegistry.addSvgIcon(
            "email",
            sanitizer.bypassSecurityTrustResourceUrl(
                "../../../assets/icons/filled-email-24px.svg"
            )
        );
        iconRegistry.addSvgIcon(
            "language",
            sanitizer.bypassSecurityTrustResourceUrl(
                "../../../assets/icons/filled-language-24px.svg"
            )
        );
        iconRegistry.addSvgIcon(
            "category",
            sanitizer.bypassSecurityTrustResourceUrl(
                "../../../assets/icons/filled-category-24px.svg"
            )
        );
        iconRegistry.addSvgIcon(
            "company",
            sanitizer.bypassSecurityTrustResourceUrl(
                "../../../assets/icons/filled-company-24px.svg"
            )
        );
        iconRegistry.addSvgIcon(
            "country",
            sanitizer.bypassSecurityTrustResourceUrl(
                "../../../assets/icons/filled-country-24px.svg"
            )
        );
        iconRegistry.addSvgIcon(
            "city",
            sanitizer.bypassSecurityTrustResourceUrl(
                "../../../assets/icons/filled-city-24px.svg"
            )
        );
        iconRegistry.addSvgIcon(
            "house",
            sanitizer.bypassSecurityTrustResourceUrl(
                "../../../assets/icons/filled-house-24px.svg"
            )
        );
        iconRegistry.addSvgIcon(
            "pin",
            sanitizer.bypassSecurityTrustResourceUrl(
                "../../../assets/icons/filled-pin-24px.svg"
            )
        );
        this.unsubscription$ = new Subject<void>();
        // this.translations();

        // Si ya esta logeado, lo redirige a EPG
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(["epg/networks"]);
        }
    }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            firstName: ["", Validators.required],
            lastName: ["", Validators.required],
            email: ["", [Validators.required, Validators.email]],
            password: ["", [Validators.required, Validators.minLength(8)]],
            confirmPassword: ["", Validators.required],
            language: ["", Validators.required],
            category: ["", Validators.required],
            company: ["", Validators.required],
            country: ["", Validators.required],
            city: ["", Validators.required],
            address: ["", Validators.required],
            zipCode: ["", Validators.required],
            terms: [false, Validators.requiredTrue],
        }, {
            validator: this.MustMatch('password', 'confirmPassword')
        });

        this.loadCategories();
        this.loadCountries();
        this.loadLanguages();
    }


    loadCategories() {
        this.categoryService.getCategories().subscribe(
            (data) => {
                this.categories = data;
            },
            (error) => {
                console.error('Error al cargar las categorías:', error);
            }
        );
    }

    loadCountries() {
        this.countryService.getCountries().subscribe(
            (data) => {
                this.countries = data;
            },
            (error) => {
                console.error('Error al cargar los paises:', error);
            }
        );
    }

    loadLanguages() {
        this.languageService.getLanguagesSelect().subscribe(
            (data) => {
                this.languages = data;
            },
            (error) => {
                console.error('Error al cargar los lenguajes:', error);
            }
        );
    }

    ngOnDestroy(): void {
        this.unsubscription$.next();
        this.unsubscription$.complete();
    }

    get f() {
        return this.registerForm.controls;
    }

    onSubmit() {
        this.submitted = true;

        this.loading = true;

        if (this.registerForm.invalid) {
            this.loading = false;
            return;
        }

        const formValues = this.registerForm.value;

        this.newUser = {
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            username: formValues.email,
            password: formValues.password,
            language: formValues.language,
            type: formValues.category,
            address: {
                city: formValues.city,
                country: formValues.country,
                street: formValues.address,
                zipCode: formValues.zipCode,
                version: 0,
            },
            company: {
                name: formValues.company,
                version: 0,
            },
            enabled: false,
            version: 0,
            accountExpired: false,
            accountLocked: false,
            passwordExpired: false,
            dateCreated: "",
            userClass: "com.turner.dmtla.tapkit.user.User",
            state: 0,
        };

        console.log("Registrando nuevo usuario:", this.newUser);

        this.authenticationService
            .register$(this.newUser)
            .pipe(takeUntil(this.unsubscription$))
            .subscribe(
                (response) => {
                    console.log("Respuesta del registro:", response);

                    if (response.success) {
                        this.successMessageKey = true;
                        setTimeout(() => {
                            this.router.navigate(["/login"]);
                        }, 5000);
                    } else {
                        if (response.message === "UserAlreadyExists") {
                            this.userAlreadyExistsMessageKey = true;
                            setTimeout(() => {
                                this.userAlreadyExistsMessageKey = false;
                            }, 3000);
                        } else if (response.message === "InvalidEmailDomain") {
                            this.invalidEmailMessageKey = true;
                        } else {
                            this.errorMessageKey = true;
                        }
                    }
                    this.loading = false;
                },
                (error) => {
                    console.error("Error al registrar el usuario:", error);
                    this.errorMessageKey = true;
                    this.loading = false;
                }
            );
    }


    // custom validator to check that two fields match
    MustMatch(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];

            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                return;
            }

            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ mustMatch: true });
            } else {
                matchingControl.setErrors(null);
            }
        };
    }

    notDefault(control: AbstractControl): { [key: string]: boolean } | null {
        if (control.value === "") {
            return { default: true };
        }
        return null;
    }

    onPasswordChange() {
        const password = this.registerForm.get("password").value;
        this.passwordStrength = this.calculatePasswordStrength(password);
    }

    calculatePasswordStrength(password: string): number {
        let strength = 0;

        if (password.length >= 8) {
            strength++;
        }
        if (/[a-z]/.test(password)) {
            strength++;
        }
        if (/[A-Z]/.test(password)) {
            strength++;
        }
        if (/[0-9]/.test(password)) {
            strength++;
        }

        return strength;
    }

    getPasswordStrengthClass(): string {
        if (this.passwordStrength < 2) {
            return "bg-danger";
        } else if (this.passwordStrength < 4) {
            return "bg-warning";
        } else {
            return "bg-success";
        }
    }

    getPasswordStrengthMessage(): string {
        if (this.passwordStrength < 2) {
            return this.translateService.instant("signup.passwordTooShort");
        } else if (this.passwordStrength < 4) {
            return this.translateService.instant("signup.passwordWeak");
        } else {
            return this.translateService.instant("signup.passwordSecure");
        }
    }

    showDialog(): void {
        const language = this.registerForm.get("language").value;
        console.log("Lenguaje activo:", language);
        const dialogRef = this.dialog.open(TermsComponent, {
            data: { language: language },
        });
    }

    togglePasswordVisibility() {
        this.passwordVisible = !this.passwordVisible;
    }

    toggleConfirmPasswordVisibility() {
        this.confirmPasswordVisible = !this.confirmPasswordVisible;
    }
}
