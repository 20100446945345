import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AlertService } from "src/app/shared/service/alert.service";
import { AuthenticationService } from "../../shared/service/authentication.service";
import { AuthorizationService } from "src/app/shared/service/authorization.service";
import { Role } from "src/app/shared/model/role";
import { UrlService } from "src/app/shared/service/url.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit, OnDestroy {
    loginForm: FormGroup;
    loading = false;
    submitted = false;

    private unsubscription$: Subject<void>;
    redirectUrl$: string | null;

    errorLogin: string;

    errorLoginMessageKey: boolean = false;
    pendingActivationMessageKey: boolean = false;
    accountRejectedMessageKey: boolean = false;
    inactiveAccountMessageKey: boolean = false;
    noRoleAccountMessageKey: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService,
        private authorizationService: AuthorizationService,
        private alertService: AlertService,
        private urlService: UrlService,
        private translateService: TranslateService,
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer
    ) {
        iconRegistry.addSvgIcon(
            "person",
            sanitizer.bypassSecurityTrustResourceUrl(
                "../../../assets/icons/filled-person-24px.svg"
            )
        );
        iconRegistry.addSvgIcon(
            "lock",
            sanitizer.bypassSecurityTrustResourceUrl(
                "../../../assets/icons/filled-lock-24px.svg"
            )
        );
        this.unsubscription$ = new Subject<void>();
        this.translations();

        // Si ya esta logeado, lo redirige a EPG
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(["epg/networks"]);
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ["", [Validators.required, Validators.email]],
            password: ["", Validators.required],
            rememberMe: [false],
        });
    }

    ngOnDestroy(): void {
        this.unsubscription$.next();
        this.unsubscription$.complete();
    }

    get f() {
        return this.loginForm.controls;
    }

    onSubmit() {
        this.submitted = true;
        this.clearMessages();

        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;

        this.authenticationService
            .login$(
                this.f.username.value,
                this.f.password.value,
                this.f.rememberMe.value
            )
            .pipe(takeUntil(this.unsubscription$))
            .subscribe({
                next: () => {
                    this.routeRole();
                },
                error:
                    (error) => {
                        console.log(error)
                        switch (error) {
                            case "0": // APPROVING_PENDING
                            this.pendingActivationMessageKey = true;
                            this.loading = false;
                            case "1": // ACTIVATION_PENDING
                                this.pendingActivationMessageKey = true;
                                this.loading = false;
                                break;
                            case "2": // REJECTED
                                this.accountRejectedMessageKey = true;
                                this.loading = false;
                                break;
                            case "3": // ACTIVE
                                this.routeRole();
                                break;
                            case "4": // INACTIVE
                                this.inactiveAccountMessageKey = true;
                                this.loading = false;
                                break;
                            case "5":
                                this.noRoleAccountMessageKey = true;
                                this.loading = false;
                                break;
                            default:
                                if (error == 400) {
                                    this.alertService.error(this.errorLogin);
                                    this.loading = false;
                                } else {
                                    this.errorLoginMessageKey = true;
                                    this.loading = false;
                                }
                        }
                    },
                complete: () => {
                    this.loading = false;
                }
            }
            );
    }

    forwotPassword() {
        this.router.navigate(['password-recovery']);
    }


    private clearMessages() {
        this.errorLoginMessageKey = false;
        this.pendingActivationMessageKey = false;
        this.accountRejectedMessageKey = false;
        this.inactiveAccountMessageKey = false;
    }

    private routeRole(): void {
        const superAdmin = this.authorizationService.isAuthorized([
            Role.SuperAdmin,
        ]);
        const legal = this.authorizationService.isAuthorized([Role.Legal]);
        const user = this.authorizationService.isAuthorized([Role.User]);

        const redirectUrl = this.urlService.getRedirectUrl();
        const params = this.urlService.getParams();

        if (superAdmin || user) {
            if (redirectUrl) {
                this.urlService.clearRedirectUrl();
                this.router.navigate([redirectUrl], { queryParams: params });
            } else {
                this.router.navigate(["epg/networks"]);
            }
        } else if (legal) {
            this.router.navigate(["epg/legal"]);
        } else {
            this.router.navigate(["epg/networks"]);
        }
    }

    //Translate Error
    private translations(): void {
        const keys = ["login.form.errorLogin"];

        this.translateService
            .stream(keys)
            .pipe(takeUntil(this.unsubscription$))
            .subscribe((translations) => this.mapperToMessages(translations));
    }

    private mapperToMessages(translations: any): void {
        this.errorLogin = translations["login.form.errorLogin"];
    }



}
