import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NetworkContact } from '../model/network-contact';
import { Observable } from 'rxjs';
import { Contact } from '../model/contact';

@Injectable({
  providedIn: 'root'
})
export class NetworkContactService {
 
  constructor(
    private http: HttpClient
  ) { }

  getAllContacts(networkId: number): Observable<NetworkContact>  {
    let response = this.http.get<NetworkContact>(environment.apiNetworksContactsGetAllNetworkContacts + "/" + networkId);
    return response;
  }

  //cambiar la variable de environmmet
  addContact(networkId: number, contact: Contact): Observable<Contact> {
    return this.http.post<Contact>(`${environment.apiNetworksContactsGetAllNetworkContacts }/${networkId}/contacts`, contact);
  }

  //cambiar la variable de environmmet
  updateContact(contactId: number, contact: Contact): Observable<Contact> {
    return this.http.put<Contact>(`${environment.apiNetworksContactsGetAllNetworkContacts }/contacts/${contactId}`, contact);
  }
}
