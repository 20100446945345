
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Idle } from '@ng-idle/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from 'src/app/shared/model/user';
import { environment } from 'src/environments/environment';
import { NewUser } from '../model/new-user';
import * as moment from 'moment';
import { GeneralService } from './general.service';
import { UserProfile } from '../model/user-profile';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: "root",
})
export class AuthenticationService {
    private currentUserSubject$: BehaviorSubject<User>;
    public currentUser$: Observable<User>;
    rememberMe: boolean = false;

    constructor(
        private idle: Idle,
        private http: HttpClient,
        private gralService: GeneralService,
        private router: Router,
        private cookieService: CookieService ) {


        {
            const storedUser = this.getCookie('session_user');
            if (storedUser) {
                this.currentUserSubject$ = new BehaviorSubject<User>(JSON.parse(storedUser));
            } else {
                this.currentUserSubject$ = new BehaviorSubject<User>(null);
            }
            this.currentUser$ = this.currentUserSubject$.asObservable();
        }
    }

    public get currentUserValue(): User {
        return this.currentUserSubject$.value;
    }

    /**
     * Login al servicio de Seguridad con las Credenciales del usuario
     *
     * @param username email del usuario
     * @param password contraseña del usuario
     */
    login$(
        username: string,
        password: string,
        rememberMe: boolean
    ): Observable<any> {
        const body = `grant_type=password&scope=any&username=${username}&password=${password}`;
        return this.http.post<any>(environment.apiSecurityUrl, body).pipe(
            map((data) => {
                this.mapperUserStorage(data, new User(), rememberMe);
                this.idle.watch();
            }),
            catchError((error) => {
                return throwError(error);
            })
        );
    }

    /**
     * Login al servicio de Seguridad con el RefreshToken
     * para actualizar el access_token vencido
     * o loguearse a la app desde un link hipervinculo (Tapkit redirect)
     *
     * @param refreshToken token JWT
     */
    loginRefreshToken$(refreshToken: string): Observable<any> {
        const body = `grant_type=refresh_token&scope=any&refresh_token=${refreshToken}`;
        return this.http.post<any>(environment.apiSecurityUrl, body).pipe(
            map((data) => {
                if (this.currentUserValue) {
                    // Usuario logueado, solo actualiza access_token
                    this.mapperUserStorage(
                        data,
                        this.currentUserValue,
                        this.isRememberMe()
                    );
                } else {
                    // Login por redirect, instancia un nuevo usuario
                    this.mapperUserStorage(
                        data,
                        new User(),
                        this.isRememberMe()
                    );
                    this.idle.watch();
                }
            })
        );
    }

    /**
     * Elimina el usuario del sessionStorage
     * y setea el usuario actual en null
     */
    logout(): void {
        // this.getStorage().removeItem("session_user");
        this.cookieService.delete('session_user');
        this.idle.stop();
        this.currentUserSubject$.next(null);
        this.router.navigate(["/login"]);
    }

    // validateEmailDomain(email: string): Observable<boolean> {
    //     return this.http
    //         .post<boolean>(
    //             environment.apiSecurityUrlRegisterMailValidation,
    //             email
    //         )
    //         .pipe(
    //             catchError((error) => {
    //                 console.error("Error validating email domain", error);
    //                 return of(false); // Retorna false en caso de error
    //             })
    //         );
    // }

    register$(user: NewUser): Observable<any> {
        const now = moment().format("YYYY-MM-DD HH:mm:ss");
        user.dateCreated = now;

        user.firstName = user.firstName;
        user.lastName = user.lastName;
        user.username = user.username;
        user.language = user.language;
        user.type = user.type;
        user.address.city = user.address.city;
        user.address.country = user.address.country;
        user.address.street = user.address.street;
        user.address.zipCode = user.address.zipCode;
        user.company.name = user.company.name;

        return this.http
            .post<any>(`${environment.apiSecurityUrlRegister}`, user)
            .pipe(
                map((data) => ({ success: true, message: data })),
                catchError((error) => {
                    let errorMessage = "";
                    if (error === 400) {
                        errorMessage = "UserAlreadyExists";
                    } else if (error === 422) {
                        errorMessage = "InvalidEmailDomain";
                    } else {
                        errorMessage = "Error";
                    }
                    return of({ success: false, message: errorMessage });
                })
            );
    }

    /*
     * Mapea los datos recibidos del servicio de seguridad al usuario pasado por parametro
     * y guarda los datos del mismo en el sessionStorage
     *
     * @param data  response login
     * @param user  usuario actual (logueado)
     */
    private mapperUserStorage(
        data: any,
        user: User,
        rememberMe: boolean
    ): void {
        user.accessToken = data.access_token;
        user.refreshToken = data.refresh_token;
        user.firstName = data.first_name;
        user.lastName = data.last_name;
        user.username = data.user_name;
        user.id = data.id;


        this.setCookie('session_user', JSON.stringify(user), rememberMe);


        
        this.currentUserSubject$.next(user);
    }

    resetPassword$(email: string): Observable<any> {

        return this.http.post<any>(`${environment.apiSecurityUrlResetPassword}`, { username: email }).pipe(
            catchError(this.gralService.handleError)
        );
    }

    /**
     * Actualiza la contraseña del usuario
     *
     * @param newPassword nueva contraseña
     */
    changePassword$(oldPassword:string, newPassword: string): Observable<any> {
        // const user = new UserProfile();
        // user.password = newPassword;
        // user.username = this.currentUserValue.username;
        // user.id = this.currentUserValue.id;

        const user = {
            newPassword: newPassword,
            username: this.currentUserValue.username,
            id: this.currentUserValue.id,
            password: oldPassword
        }

        return this.http.patch<any>(`${environment.apiDomainUrlGetUserProfiles}/changePassword`, user).pipe(
            catchError(this.gralService.handleError)
        );
    }


    private getStorage(): Storage {
        return localStorage.getItem("rememberMe") === "true"
            ? localStorage
            : sessionStorage;
    }

    private setStorage(rememberMe: boolean): Storage {
        localStorage.setItem("rememberMe", rememberMe.toString());
        return rememberMe ? localStorage : sessionStorage;
    }

    private isRememberMe(): boolean {
        return localStorage.getItem("rememberMe") === "true";
    }

    private getCookie(cookieName: string): string {
        return this.cookieService.get(cookieName);
    }
    
    private setCookie(cookieName: string, value: string, rememberMe: boolean): void {
        if (rememberMe) {
            this.cookieService.set(cookieName, value, { expires: 30, path: '/' });
        } else {
            this.cookieService.set(cookieName, value, { path: '/' }); 
        }
    }
    
}
