import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { ContactService } from "src/app/shared/service/contact.service";
import { UtilService } from "src/app/shared/service/util.service";
import { ContactDialogComponent } from "./contact-dialog/contact-dialog.component";
import { FormGroup } from "@angular/forms";
import { Contact } from "src/app/shared/model/contact";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ContactType } from "src/app/shared/model/contactType";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
    selector: "app-configuration-contacts",
    templateUrl: "./configuration-contacts.component.html",
    styleUrls: ["./configuration-contacts.component.css"],
})
export class ConfigurationContactsComponent implements OnInit, AfterViewInit {
    contacts: Contact[] = [];
    selectedContacts: Contact[] = [];
    displayedColumns: string[] = [
        "select",
        "name",
        "email",
        "zone",
        "network",
        "type",
        "actions",
    ];
    contactForm: FormGroup;
    isEditing: boolean = false;
    selectedContactId?: number;
    dataSource = new MatTableDataSource<Contact>([]);
    uniqueTypes: string[] = [];
    uniqueNetworks: string[] = [];
    selectedTypes: string[] = [];
    selectedNetworks: string[] = [];
    isLoading: boolean;
    isLoadingDelete: boolean;
    isLoadingAdd: boolean;

    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private contactService: ContactService,
        private dialog: MatDialog,
        private translateService: TranslateService,
        private utilService: UtilService,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit(): void {
        this.loadContacts();

        this.dataSource.filterPredicate = (data, filter) => {
            const filters = JSON.parse(filter);
            const matchesType =
                filters.types.length === 0 ||
                filters.types.some((type) => data.contactTypes.includes(type));
            const matchesNetwork =
                filters.networks.length === 0 ||
                filters.networks.some((network) =>
                    data.networks.includes(network)
                );

            return matchesType && matchesNetwork;
        };
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    loadContacts() {
        this.isLoading = true;
        this.contactService.getAllActiveContacts().subscribe(
            (contacts) => {
                this.contacts = contacts;
                this.selectedContacts = [];

                this.uniqueTypes = Array.from(
                    new Set(
                        this.contacts.reduce((acc, contact) => {
                            contact.contactTypes.forEach((type) => {
                                if (type && !acc.includes(type)) acc.push(type);
                            });
                            return acc;
                        }, [] as string[])
                    )
                );

                this.uniqueNetworks = Array.from(
                    new Set(
                        this.contacts.reduce((acc, contact) => {
                            contact.networks.forEach((network) => {
                                if (network && !acc.includes(network))
                                    acc.push(network);
                            });
                            return acc;
                        }, [] as string[])
                    )
                );

                this.dataSource.data = this.contacts;
                this.isLoading = false;
                this.isLoadingDelete = false;
                this.isLoadingAdd = false;
            },
            (error) => {
                console.error("Error al cargar los contactos:", error);
            }
        );
    }

    toggleSelection(contact: Contact, event: any) {
        if (event.checked) {
            this.selectedContacts.push(contact);
        } else {
            const index = this.selectedContacts.indexOf(contact);
            if (index > -1) {
                this.selectedContacts.splice(index, 1);
            }
        }
    }

    toggleAllSelection(event: any) {
        if (event.checked) {
            this.selectedContacts = [...this.dataSource.data];
        } else {
            this.selectedContacts = [];
        }
    }

    isSelected(contact: Contact): boolean {
        return this.selectedContacts.includes(contact);
    }

    openAddDialog() {
        const dialogRef = this.dialog.open(ContactDialogComponent, {
            width: "400px",
            data: { contact: null, isEditing: false },
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                this.contactService.addContact(result).subscribe(() => {
                    this.loadContacts();
                });
            }
            this.isLoadingAdd = true;
            this.loadContacts();
        });
    }

    openEditDialog(contact: Contact) {
        const dialogRef = this.dialog.open(ContactDialogComponent, {
            width: "400px",
            data: { contact: { ...contact }, isEditing: true },
        });

        dialogRef.afterClosed().subscribe((updatedContact) => {
            if (updatedContact) {
                this.contactService.updateContact(updatedContact).subscribe(
                    () => {
                        this.loadContacts();
                    },
                    (error) => {
                        console.error(
                            "Error al actualizar el contacto:",
                            error
                        );
                    }
                );
            }
            this.isLoadingAdd = true;
            this.loadContacts();
        });
    }

    async deleteSelectedContacts() {
        const textConfirm = await this.translateService
            .get("epg.users.dialog.messageDeleteConfirm")
            .toPromise();
        const buttonConfirm = await this.translateService
            .get("epg.users.dialog.buttons.delete")
            .toPromise();

        const dialogRef = this.utilService.openConfirmDialog({
            title: buttonConfirm,
            text: textConfirm,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.isLoadingDelete = true;
                const selectedContactIds = this.selectedContacts.map(
                    (contact) => contact.id
                );
                this.contactService
                    .deleteContactList(selectedContactIds)
                    .subscribe(() => {
                        this.loadContacts();
                        this.snackBar.open("Contacts deleted successfully!", "Close", {
                            duration: 3000,
                            panelClass: ["SuccessfulSnackbar"],
                        });
                    });
            }

        });

    }

    onContactSelectionChange(contact: Contact) {
        if (contact.selected) {
            this.selectedContacts.push(contact);
        } else {
            this.selectedContacts = this.selectedContacts.filter(
                (c) => c.id !== contact.id
            );
        }
    }

    resetForm() {
        this.contactForm.reset();
        this.isEditing = false;
        this.selectedContactId = undefined;
    }

    applyFilter() {
        const filters = {
            types: this.selectedTypes,
            networks: this.selectedNetworks,
        };

        this.dataSource.filter = JSON.stringify(filters);
    }

    toggleFilter(value: string, filterType: "type" | "network") {
        if (filterType === "type") {
            const index = this.selectedTypes.indexOf(value);
            if (index >= 0) {
                this.selectedTypes.splice(index, 1);
            } else {
                this.selectedTypes.push(value);
            }
        } else if (filterType === "network") {
            const index = this.selectedNetworks.indexOf(value);
            if (index >= 0) {
                this.selectedNetworks.splice(index, 1);
            } else {
                this.selectedNetworks.push(value);
            }
        }
        this.applyFilter();
    }
}
