import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function uniqueCodeValidator(existingCodes: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!existingCodes || !Array.isArray(existingCodes)) {
      return null;
    }

    // Normaliza el valor introducido y los existentes a mayúsculas
    const newCode = control.value?.trim().toUpperCase();
    const isDuplicate = existingCodes.some(code => code.toUpperCase() === newCode);

    return isDuplicate ? { duplicateCode: true } : null;
  };
}
