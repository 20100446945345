import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatSort, MatSortModule} from '@angular/material/sort';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';

import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog} from '@angular/material/dialog';
import { UsersDialogComponent } from './users-dialog/users-dialog.component';
import { RoleService } from 'src/app/shared/service/role.service';
import { RoleType } from 'src/app/shared/model/role-type';
import { UserProfileService } from 'src/app/shared/service/user-profile.service';
import { UserProfileResponse } from 'src/app/shared/response/user-profile-response';
import { UserProfile } from 'src/app/shared/model/user-profile';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from 'src/app/shared/service/util.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar'
import { ConfigGridAlias } from 'src/app/shared/model/config-grid-alias';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})


export class UsersComponent implements OnInit {
    displayedColumns: string[] = ['firstName', 'lastName', 'username', 'state','Acciones'];
    //displayedColumns: string[] = ['firstName', 'lastName', 'username', 'state'];
    dataSource: MatTableDataSource<UserProfile>;
    selection = new SelectionModel<UserProfile>(true, []);
    roles: RoleType[] = [];

    currentPage: number = 0;
    pageSize: number = 15;
    totalSize: number = 0;

    selectedUserProfile: UserProfile[] = [];
    loadingUser: boolean = true;
    loadingDelete: boolean = false;
    loadingActivate: boolean = false;
    loadingApprove: boolean = false;
    loadingReject: boolean = false;

    filterValue: string = '';

    private filterSubject = new BehaviorSubject<string>('');


    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort ) sort: MatSort = new MatSort();

    constructor(
        private roleService: RoleService,
        private userProfileService: UserProfileService,
        private translateService: TranslateService,
        private utilService: UtilService,
        private matSnackBar: MatSnackBar,
        private dialog: MatDialog,
    ) {
        this.dataSource = new MatTableDataSource<UserProfile>([]);

        this.roleService.getRoles$().subscribe(
            (data:any) => {
                this.roles = data.roles;
                localStorage.setItem('roles', JSON.stringify(this.roles));
            });
        this.loadUsers(this.currentPage, this.pageSize);
    }

  ngOnInit(): void {
    this.filterSubject.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        filter(value => value.length === 0 || value.length >= 3),
    ).subscribe(filterValue => {
        this.currentPage = 0;
        this.loadUsers(this.currentPage, this.pageSize, filterValue.trim().toLowerCase());
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() =>{
        this.currentPage = 0;
        this.loadUsers(this.currentPage, this.pageSize);
    });
  }

  loadUsers(page: number, size: number, keyword?:string){
    const sortField = this.sort.active;
    const sortDirection = this.sort.direction;

    const key = keyword ? keyword : null;

    this.loadingUser = true;
    this.userProfileService.getUserProfile$(page, size, sortField, sortDirection, key).subscribe(
        (data:UserProfileResponse) => {
            this.dataSource = new MatTableDataSource(data.userProfiles);
            this.totalSize = data.totalElements;
            this.loadingUser = false;
        },
        error => {
            console.log(error);
        }
    );
  }

  updateProfileStatus(state: number){
    //const filteredUserProfile = this.selectedUserProfile.filter((user:UserProfile) => user.state === 0);
    const ids = this.selectedUserProfile.map((user:UserProfile) => user.id);

    this.userProfileService.updateProfileStatus(ids, state).subscribe(
        (data:UserProfileResponse) => {
            this.selection.clear();
            this.selectedUserProfile = [];

            //busco indices de los elementos presentes en data dentro de this.dataSource.data
            const indexs:number[] = data.userProfiles.map((user:UserProfile) => this.dataSource.data.findIndex((user2:UserProfile) => user2.id === user.id));
            this.dataSource.data.forEach((user:UserProfile, index:number) => {
                if(indexs.includes(index)){
                    const user2 = data.userProfiles.find((user3:UserProfile) => user3.id === user.id);
                    this.dataSource.data[index] = user2;
                }
            });
            this.dataSource._updateChangeSubscription();
        },
        error => {
            console.log(error);
        }
    );
  }

  onChangePage(event: any) {
    this.loadUsers(event.pageIndex, event.pageSize);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  openDialog(row:UserProfile){
    this.dialog.open(UsersDialogComponent, {
      data: row,
      minWidth: '70%',
    }).afterClosed().subscribe((result: UserProfile) => {
        const index = this.dataSource.data.findIndex((user:UserProfile) => user.id === result.id);
        if(localStorage.getItem('operation') === 'delete'){
            this.dataSource.data.splice(index, 1);
            this.dataSource._updateChangeSubscription();
            localStorage.removeItem('operation');
            return;
        }
        this.dataSource.data[index] = result;
        this.dataSource._updateChangeSubscription();
    });
  }

  toggleAllRows() {
    console.log('toggleAllRows', this.isAllSelected());
    if (this.isAllSelected()) {
        this.selection.clear();
        this.selectedUserProfile = [];
    }
    else {
        this.selection.select(...this.dataSource.data);
        this.selectedUserProfile = [...this.dataSource.data];
    }
  }

  checkboxLabel(): string {
    if (this.isAllSelected()) {
        return 'deselect all';
    }
    else {
        return 'select all';
    }
  }

  toogleUserProfileSelection(row: UserProfile):void {
    const index = this.selectedUserProfile.findIndex((user:UserProfile) => user.id === row.id);
    if(index > -1){
        this.selectedUserProfile.splice(index, 1);
        this.selection.select(...this.selectedUserProfile);
        if(this.selectedUserProfile.length === 0)
            this.selection.clear();

    }
    else{
        this.selectedUserProfile.push(row);
        this.selection.select(...this.selectedUserProfile);
    }
  }



  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    this.filterSubject.next(filterValue);
    // if(filterValue.length === 0){
    //     this.loadUsers(this.currentPage, this.pageSize);
    // }
    // if(filterValue.length >= 3){
    //     this.loadUsers(this.currentPage, this.pageSize, filterValue.trim().toLowerCase());
    // }

  }

  clearFilter(){
    this.filterValue = '';
    this.filterSubject.next('');
  }

  getState(state:number){
    if(state === 0){
      return 'PENDIENTE DE APROBACIÓN';
    }

    if(state === 1){
      return 'NO CONFIRMADO';
    }

    if(state === 2){
      return 'RECHAZADO';
    }

    if(state === 3){
      return 'ACTIVO';
    }

    if(state === 4){
      return 'INACTIVO';
    }
  }

  openErrorSnackBar(message: string, action: string) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = "top";
    config.panelClass = ['error-snackbar'];
    config.duration = 8000;
    this.matSnackBar.open(message, action, config);
  }

  openSuccessSnackBar(message: string, action: string) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = "top";
    config.panelClass = ['success-snackbar'];
    config.duration = 8000;
    this.matSnackBar.open(message, action, config);
  }

  async deleteAll(){
    let textConfirm = await this.translateService.get('epg.users.dialog.messageDeleteAll').toPromise();
    let buttonConfirm = await this.translateService.get('epg.users.dialog.buttons.confirm').toPromise() ;

    this.utilService.openConfirmDialog({
        title: buttonConfirm,
        text: textConfirm,
        count: this.selectedUserProfile.length
      }).afterClosed().subscribe((result) => {
        console.log(result);
        if(result){
            this.loadingDelete = true;

            const ids = this.selectedUserProfile.map((user:UserProfile) => user.id);

            this.userProfileService.removeUsersProfiles(ids).subscribe(
                (data:UserProfileResponse) => {
                    this.loadingDelete = false;
                    this.selection.clear();
                    this.selectedUserProfile = [];
                    this.loadUsers(this.currentPage, this.pageSize, this.filterValue.trim().toLowerCase());
                    this.translateService.get('epg.users.dialog.message-success-delete').subscribe((msg: string) => {
                        this.openSuccessSnackBar(msg, 'Close');
                    });
                },
                error => {
                    this.loadingDelete = false;
                    this.translateService.get('epg.users.dialog.message-error-delete').subscribe((msg: string) => {
                        this.openErrorSnackBar(msg, 'Close');
                    });
                    console.log(error);
                }
            );

        }
     })

  }

}


