import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-network-dialog',
  templateUrl: './delete-network-dialog.component.html',
  styleUrls: ['./delete-network-dialog.component.css']
})
export class DeleteNetworkDialogComponent implements OnInit {
  deactivationDate: Date;
  minDate: Date; 

  constructor(    
    public dialogRef: MatDialogRef<DeleteNetworkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { networkName: string }
) { }

  ngOnInit(): void {
    this.minDate = new Date();
    
  }

  onConfirm(): void {
    this.dialogRef.close({ confirmed: true, date: this.deactivationDate });
  }

  onCancel(): void {
    this.dialogRef.close({ confirmed: false });
  }

}
